import { useEffect } from "react";
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import axios from "axios";

if (import.meta.env.VITE_USE_SENTRY === "true") {
  Sentry.init({
    environment: import.meta.env.VITE_APP_NAME,
    dsn: __SENTRY_DSN__,
    tracesSampleRate: 0.1, //トランザクションの保存比率(0～1)
    replaysSessionSampleRate: 0.01, // ユーザーセッションに対するリプレイ保存比率(0～1)
    replaysOnErrorSampleRate: 1, // エラーに対するリプレイ保存比率(0～1)
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function sentryLog(err: unknown) {
  if (axios.isAxiosError(err)) {
    let contexts = {};
    const response = err.response;
    const status = response?.status;
    const endpoint = response?.config.url || '';
    const method = response?.config.method || '';

    contexts = { response };

    Sentry.withScope((scope) => {
      scope.setFingerprint(['{{ default }}', endpoint, String(status), method]);
      Sentry.captureException(err, {
        contexts,
      });
    });
  } else {
    Sentry.captureException(err);
  }
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);